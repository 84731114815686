
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PdfRenderer extends Vue {
  @Prop({ required: true }) content!: string;
  
  // PDF içeriğine erişmek için ref kullanımı
  $refs!: {
    pdfContent: HTMLElement;
  };

  // İçeriğin HTML olarak render edilmesini sağlar
  getPdfHtmlContent(): HTMLElement {
    return this.$refs.pdfContent;
  }
  
  // İçeriği temizlenmiş HTML olarak alın
  getCleanHtml(): string {
    // Gömülü stilleri temizleyen fonksiyon
    const cleanHtml = this.content
      .replace(/<style[^>]*>.*?<\/style>/gs, '') // Gömülü stil etiketlerini kaldır
      .replace(/<img/g, '<img style="max-width: 100%; height: auto;"'); // Görselleri düzelt
      
    // PDF özel stillerini içeren bir wrapper ekle
    return `
      <div style="width: 100%; margin: 0; padding: 0; font-family: Arial, sans-serif;">
        <!-- PDF için özel stiller -->
        <style>
          /* Genel sıfırlama */
          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box !important;
            font-family: Arial, sans-serif !important;
            line-height: 1.5;
            color: #000;
          }
          
          /* Temel yazı tipi tanımları */
          body, html {
            font-family: Arial, sans-serif !important;
            font-size: 12pt;
            background-color: white !important;
            color: black !important;
          }
          
          /* Başlıklar */
          h1 {
            font-size: 18pt;
            font-weight: bold;
            margin: 14pt 0;
            page-break-after: avoid;
          }
          
          h2 {
            font-size: 16pt;
            font-weight: bold;
            margin: 12pt 0;
            page-break-after: avoid;
          }
          
          h3 {
            font-size: 14pt;
            font-weight: bold;
            margin: 10pt 0;
            page-break-after: avoid;
          }
          
          /* Paragraf */
          p {
            margin: 8pt 0;
            text-align: justify;
          }
          
          /* Tablolar */
          table {
            width: 100%;
            border-collapse: collapse;
            margin: 12pt 0;
            page-break-inside: avoid;
          }
          
          th, td {
            border: 1px solid #000;
            padding: 8pt;
            text-align: left;
          }
          
          /* Sayfa kesmeleri */
          .page-break-before {
            page-break-before: always;
          }
          
          .page-break-after {
            page-break-after: always;
          }
          
          .page-break-avoid {
            page-break-inside: avoid;
          }
        </style>
        
        ${cleanHtml}
      </div>
    `;
  }
}
